
import React from "react";
import { useTranslation } from "react-i18next";
import HeroImg from "../../components/HeroImg/HeroImg";
import LeftRightBox from "../../components/LeftRightBox/LeftRightBox";
import MainLayout from "../../layouts/MainLayout";
import "./gorda.scss";

const BrandGorda = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("gorda:metaTitle")}>
      <>
        <HeroImg
          customClass="brand-gorda-hero"
          backgroundImage="bg/brand-gorda-hero.jpg"
        >
          {<>
            <h1 className="brand-gorda-hero-title mobile-position">{t("gorda:heroTitle")}</h1>
            <h1 className="brand-gorda-hero-title">{t("gorda:heroTitleTwo")}</h1>
            <div className="position-signature">
              <img src="/img/signature.svg" className="signature" alt="signature" />
            </div>
          </>}
        </HeroImg>

        <div className="brand-gorda">

          <div className="divider"></div>

          <div className="brand-gorda-section-two">
            <LeftRightBox >
              {<img src="/img/brand-gorda-one.jpg" alt="gorda-one" />}
              {<div className="text-wrap">
                <h3 className="box-two">{t("gorda:sectionOneTitle")}</h3>
                <p className="box">{t("gorda:sectionOneText")}</p>
              </div>}
            </LeftRightBox>

            <LeftRightBox customClass="reverse">
              {<div className="text-wrap">
                <h3 className="box-two">{t("gorda:sectionTwoTitle")}</h3>
                <p className="box">{t("gorda:sectionTwoText")}</p>
              </div>}
              {<img src="/img/brand-gorda-two.jpg" alt="gorda-two" />}
            </LeftRightBox>

            <LeftRightBox >
              {<img src="/img/brand-gorda-three.jpg" alt="gorda-three" />}
              {<div className="text-wrap">
                <h3 className="box-two">{t("gorda:sectionThreeTitle")}</h3>
                <p className="box">{t("gorda:sectionThreeText")}</p>
              </div>}
            </LeftRightBox>
          </div>

          <div className="brand-gorda-bottom-container">
            <HeroImg customClass="brand-gorda-bottom" backgroundImage="bg/brand-gorda-bottom.jpg">
              {
                <div className="brand-gorda-bottom-text">
                  <h3 className="box">{t("gorda:sectionFourTitle")}</h3>
                  <p>{t("gorda:sectionFourText")}</p>
                </div>
              }
            </HeroImg>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default BrandGorda;
